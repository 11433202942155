import React from 'react';


const PolicyPrivacy = () => {
  return (
    <div className="policy-container">
      <div className="policy-content">
        <h1> Informativa sulla Privacy</h1>
        <p>
          La presente informativa sulla privacy descrive le modalità di raccolta, utilizzo e protezione dei dati personali che raccogliamo attraverso il nostro modulo di prenotazione. 

          I dati personali che inserisci nel modulo di prenotazione verranno memorizzati in un database protetto e sicuro. Questi dati sono accessibili solo al personale autorizzato e saranno utilizzati esclusivamente per la gestione degli ordini ricevuti e per l'esecuzione del servizio richiesto.

          Garantiamo che i tuoi dati non verranno condivisi con terze parti, venduti o utilizzati per scopi diversi da quelli previsti. Non cediamo le informazioni a enti esterni o aziende, e ci impegniamo a mantenerle confidenziali e protette contro accessi non autorizzati.

          Il nostro database è protetto da misure di sicurezza avanzate per prevenire accessi non autorizzati, perdite di dati o danni. La tua privacy è una priorità per noi e lavoriamo costantemente per garantire la sicurezza e la protezione dei tuoi dati personali.

          Per qualsiasi domanda o richiesta relativa alla nostra politica sulla privacy, ti preghiamo di contattarci attraverso i nostri canali ufficiali.
        </p>
      </div>
    </div>
  );
};

export default PolicyPrivacy;